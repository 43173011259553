// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loadingScreen{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
}


#loadingScreen_LogoContainer{
    width: max(250px,20%);
    aspect-ratio: 1/1;
    animation-name: fluct;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    opacity: 0.6;
}




@keyframes fluct{
    from{ scale: 1;}
    50% {
        scale: 1.1;
        opacity: 1;
    }
    to{ scale: 1.0;}
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingScreen/LoadingScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB;;;AAGA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,qBAAqB;IACrB,wBAAwB;IACxB,yBAAyB;IACzB,mCAAmC;IACnC,YAAY;AAChB;;;;;AAKA;IACI,MAAM,QAAQ,CAAC;IACf;QACI,UAAU;QACV,UAAU;IACd;IACA,IAAI,UAAU,CAAC;AACnB","sourcesContent":["#loadingScreen{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100dvh;\n}\n\n\n#loadingScreen_LogoContainer{\n    width: max(250px,20%);\n    aspect-ratio: 1/1;\n    animation-name: fluct;\n    animation-duration: 1.5s;\n    animation-fill-mode: both;\n    animation-iteration-count: infinite;\n    opacity: 0.6;\n}\n\n\n\n\n@keyframes fluct{\n    from{ scale: 1;}\n    50% {\n        scale: 1.1;\n        opacity: 1;\n    }\n    to{ scale: 1.0;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
