
import './App.css';

import { Routes, Route } from "react-router-dom";

import { useState, lazy, Suspense } from 'react';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';


const Home = lazy(() => { return import("./pages/Home") });
const AboutUs = lazy(() => { return import("./pages/AboutUsPage") });
const PortfolioPage = lazy(() => { return import("./pages/PortfolioPage") });
const FAQPages = lazy(() => { return import("./pages/FAQPage") });
const FormCardPage = lazy(() => { return import("./pages/FormCardPage") });
const NavigationBar = lazy(() => import('./components/Navigation/NavigationBar'));

function App() {
  let [thisshowMobileNavBar, thissetMobileNavBar] = useState(false);

  return (
    <Routes>

      <Route path="/" element={<NavigationBar showMobileNavBar={thisshowMobileNavBar} setMobileNavBar={thissetMobileNavBar} />}>
        <Route path="/" element={<Suspense fallback={<LoadingScreen />}>
          <Home showMobileNavBar={thisshowMobileNavBar} setMobileNavBar={thissetMobileNavBar} />
        </Suspense>
        } />

        <Route path="/aboutUs" element={<Suspense fallback={<LoadingScreen />}><AboutUs /></Suspense>} />
        <Route path="/faq" element={<Suspense fallback={<LoadingScreen />}><FAQPages /></Suspense>} />
        <Route path="/portfolio" element={<Suspense fallback={<LoadingScreen />}><PortfolioPage /></Suspense>} />
        <Route path="/formCard" element={<Suspense fallback={<LoadingScreen />}><FormCardPage /></Suspense>} />
      </Route>
    </Routes>

  );
}

export default App;
