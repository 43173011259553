import './LoadingScreen.css';

const LoadingScreen =()=>{
    return (
        <div id='loadingScreen'>
            <div id='loadingScreen_LogoContainer'>
                Loading...
            </div>
        </div>
    )
}


export default LoadingScreen;